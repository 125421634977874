import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private jwtHelper = new JwtHelperService();

  constructor() {}

  decode<T>(token: string): T | null {
    try {
      return this.jwtHelper.decodeToken(token);
    } catch (err) {
      console.error('Error decoding token', err);
      return null;
    }
  }

  isExpired(token: string): boolean {
    return this.jwtHelper.isTokenExpired(token);
  }
}
