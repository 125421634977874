import { computed, inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DataCreateAnswerApiRequestDto,
  DataCreateBackofficeUserApiRequestDto,
  DataCreateBackofficeUserApiResponseDto,
  DataCreateEditionApiRequestDto,
  DataCreatePersonApiRequestDto,
  DataCreatePersonCompanyProfileApiRequestDto,
  DataCreatePersonContactApiRequestDto,
  DataCreatePrivacyEventsApiRequestDto,
  DataCreateQuestionApiRequestDto,
  DataCreateQuestionTextApiRequestDto,
  DataCreateTouchpointPersonIngressApiResponseDto,
  DataUpdateBackofficeUserApiRequestDto,
  DataUpdateEditionApiRequestDto,
  DataUpdatePersonApiRequestDto,
  DataUpdatePersonCompanyProfileApiResponseDto,
  DataUpdatePersonContactApiRequestDto,
  DataUpdateQuestionApiRequestDto,
  DataUpdateQuestionTextApiRequestDto,
  DataUpsertPersonProfileApiRequestDto,
  QueryGetPersonApiRequestDto,
  QueryListBackofficeUsersApiRequestDto,
  QueryListCompaniesApiRequestDto,
  QueryListEditionsApiRequestDto,
  QueryListPersonCompanyProfilesApiRequestDto,
  QueryListPersonContactsApiRequestDto,
  QueryListPersonsApiRequestDto,
  QueryListQuestionsApiRequestDto,
  QueryListQuestionTextsApiRequestDto,
  QueryListTouchpointPersonIngressesApiRequestDto,
  DataGetBackofficeUserApiResponseDto,
  DataCreateQuestionApiResponseDto,
  DataListQuestionsApiResponseDto,
  DataUpdateQuestionApiResponseDto,
  DataGetQuestionApiResponseDto,
  DataDeleteQuestionApiResponseDto,
  DataCreateQuestionTextApiResponseDto,
  DataListQuestionTextsApiResponseDto,
  DataUpdateQuestionTextApiResponseDto,
  DataGetQuestionTextApiResponseDto,
  DataDeleteQuestionTextApiResponseDto,
  DataCreateEditionApiResponseDto,
  DataListEditionsApiResponseDto,
  DataUpdateEditionApiResponseDto,
  DataGetEditionApiResponseDto,
  DataDeleteEditionApiResponseDto,
  DataListCompaniesApiResponseDto,
  DataGetCompanyApiResponseDto,
  DataCreatePersonApiResponseDto,
  DataListPersonsApiResponseDto,
  DataUpdatePersonApiResponseDto,
  DataGetPersonApiResponseDto,
  DataCreatePrivacyEventsApiResponseDto,
  DataPrivacyRegisterApiResponseDto,
  DataUpsertPersonProfileApiResponseDto,
  DataGetPersonProfileApiResponseDto,
  DataCreatePersonContactApiResponseDto,
  DataListPersonContactsApiResponseDto,
  DataUpdatePersonContactApiResponseDto,
  DataGetPersonContactApiResponseDto,
  DataDeletePersonContactApiResponseDto,
  DataCreatePersonCompanyProfileApiResponseDto,
  DataListPersonCompanyProfilesApiResponseDto,
  DataGetPersonCompanyProfileApiResponseDto,
  DataDeletePersonCompanyProfileApiResponseDto,
  DataCreateTouchpointPersonIngressApiRequestDto,
  DataUpdatePersonCompanyProfileApiRequestDto,
  DataListTouchpointPersonIngressesApiResponseDto,
  DataGetTouchpointPersonIngressApiResponseDto,
  DataCreateAnswerApiResponseDto,
  DataListLegalEntitiesApiResponseDto,
  GetTokenJwtApiRequestDto,
  DataGetTokenJwtApiResponseDto,
  DataCreateTouchpointPrivacyConfigurationRequestDto,
  DataCreateTouchpointPrivacyConfigurationResponseDto,
  QueryListTouchpointPrivacyConfigurationRequestDto,
  DataListTouchpointPrivacyConfigurationResponseDto,
  DataUpdateTouchpointPrivacyConfigurationRequestDto,
  DataUpdateTouchpointPrivacyConfigurationResponseDto,
  QueryPrivacyRegisterApiRequestDto,
  DataListBackofficeUsersApiResponseDto,
  DataUpdateBackofficeUserApiResponseDto,
  DataGetTouchpointPrivacyConfigurationResponseDto,
  DataDeleteTouchpointPrivacyConfigurationResponseDto,
} from '@packages/api-cmd-type-definitions';
import { Observable } from 'rxjs';
import { SessionStorageService } from './session-storage.service';
import { environment } from '../../environments/environment';
import { AppStateService } from './app-state.service';

type urlType = `${'http' | 'https'}://${string}`;

@Injectable({
  providedIn: 'root',
})
export class ApiCmdService {
  private storageService = inject(SessionStorageService);
  private apiUrl: urlType = environment.apiUrl;
  private appState = inject(AppStateService);
  private $legalEntityCodeSelected = computed(() => this.appState.$legalEntityCodeSelected());

  private endpoints = {
    authSamlJwt: '/api/auth/saml/jwt',
    authTokenJwt: '/api/auth/token/jwt',
    legalEntities: '/api/v1/legal-entities',
    touchpointPrivacyConfigurations: '/api/v1/touchpoint-privacy-configurations',
    backofficeUsers: '/api/v1/backoffice-users',
    questions: '/api/v1/questions',
    questionTexts: 'question-texts',
    companies: '/api/v1/companies',
    editions: '/api/v1/editions',
    persons: '/api/v1/persons',
    personContacts: 'contacts',
    personProfile: 'profiles',
    personCompanyProfiles: 'company-profiles',
    personAnswers: 'answers',
    personTouchpointIngresses: 'touchpoint-ingresses',
    personPrivacy: 'privacy',
  };

  constructor(private http: HttpClient) {}

  private concat(url: urlType, fragment: string[] = []): urlType {
    return url.concat(fragment.map(f => f).join('/')) as urlType;
  }

  private request<T>(url: string, method: 'get' | 'post' | 'patch' | 'put' | 'delete', body?: any): Observable<T> {
    const jwt = this.storageService.getTokenJwt();
    const legalEntityCode = this.$legalEntityCodeSelected().code;
    return this.http.request<T>(method, url, {
      body: body,
      headers: {
        ['Authorization']: jwt ? `Bearer ${jwt}` : '',
        ['Content-Type']: 'application/json',
        ['X-Legal-Entity-Code']: legalEntityCode ?? '',
      },
      responseType: 'json',
    });
  }

  /*private download(url: string, method: 'get', responseType: 'arraybuffer' | 'blob', body?: any): Observable<any> {
    return this.http.request(method, url, {
      body: body,
      headers: {
        ['Authorization']: `Bearer ${this.bearerToken}`,
        // ['Content-Type']: this.contentType,
        ['X-Legal-Entity-Code']: this.legalEntityCode,
      },
      responseType: responseType,
    });
  }*/

  private buildUrl(fragment: string[] = [], query: Record<string, any> = {}): string {
    const url = new URL(this.concat(this.apiUrl, fragment));
    for (const param in query) {
      url.searchParams.set(param, String(query[param]));
    }
    return url.toString();
  }

  /*
   * API-BACKOFFICE
   */

  // auth saml jwt
  getTokenJwtFromSaml(dto: GetTokenJwtApiRequestDto): Observable<DataGetTokenJwtApiResponseDto> {
    return this.request<DataGetTokenJwtApiResponseDto>(this.buildUrl([this.endpoints.authSamlJwt]), 'post', dto);
  }
  getTokenJwtFromIdToken(idToken: string): Observable<DataGetTokenJwtApiResponseDto> {
    return this.http.request<DataGetTokenJwtApiResponseDto>('get', this.buildUrl([this.endpoints.authTokenJwt]), {
      headers: {
        ['authorization']: `${idToken}`,
        ['Content-Type']: 'application/json',
      },
      responseType: 'json',
    });
  }

  // legal entities

  listLegalEntities(): Observable<DataListLegalEntitiesApiResponseDto> {
    return this.request<DataListLegalEntitiesApiResponseDto>(this.buildUrl([this.endpoints.legalEntities]), 'get');
  }

  // touchpoint privacy config
  createTouchpointPrivacyConfig(dto: DataCreateTouchpointPrivacyConfigurationRequestDto): Observable<DataCreateTouchpointPrivacyConfigurationResponseDto> {
    return this.request<DataCreateTouchpointPrivacyConfigurationResponseDto>(this.buildUrl([this.endpoints.touchpointPrivacyConfigurations]), 'post', dto);
  }

  listTouchpointPrivacyConfig(query: QueryListTouchpointPrivacyConfigurationRequestDto): Observable<DataListTouchpointPrivacyConfigurationResponseDto> {
    return this.request<DataListTouchpointPrivacyConfigurationResponseDto>(this.buildUrl([this.endpoints.touchpointPrivacyConfigurations], query), 'get');
  }

  updateTouchpointPrivacyConfig(
    touchpointPrivacyConfigId: string,
    dto: DataUpdateTouchpointPrivacyConfigurationRequestDto
  ): Observable<DataUpdateTouchpointPrivacyConfigurationResponseDto> {
    return this.request<DataUpdateTouchpointPrivacyConfigurationResponseDto>(
      this.buildUrl([this.endpoints.touchpointPrivacyConfigurations, touchpointPrivacyConfigId]),
      'patch',
      dto
    );
  }

  getTouchpointPrivacyConfig(touchpointPrivacyConfigId: string): Observable<DataGetTouchpointPrivacyConfigurationResponseDto> {
    return this.request<DataGetTouchpointPrivacyConfigurationResponseDto>(this.buildUrl([this.endpoints.touchpointPrivacyConfigurations, touchpointPrivacyConfigId]), 'get');
  }

  deleteTouchpointPrivacyConfig(touchpointPrivacyConfigId: string): Observable<DataDeleteTouchpointPrivacyConfigurationResponseDto> {
    return this.request<DataDeleteTouchpointPrivacyConfigurationResponseDto>(this.buildUrl([this.endpoints.touchpointPrivacyConfigurations, touchpointPrivacyConfigId]), 'delete');
  }

  // backoffice users

  createBackofficeUser(dto: DataCreateBackofficeUserApiRequestDto): Observable<DataCreateBackofficeUserApiResponseDto> {
    return this.request<DataCreateBackofficeUserApiResponseDto>(this.buildUrl([this.endpoints.backofficeUsers]), 'post', dto);
  }

  listBackofficeUsers(query: QueryListBackofficeUsersApiRequestDto): Observable<DataListBackofficeUsersApiResponseDto> {
    return this.request<DataListBackofficeUsersApiResponseDto>(this.buildUrl([this.endpoints.backofficeUsers], query), 'get');
  }

  updateBackofficeUser(backofficeUserId: string, dto: DataUpdateBackofficeUserApiRequestDto): Observable<DataUpdateBackofficeUserApiResponseDto> {
    return this.request<DataUpdateBackofficeUserApiResponseDto>(this.buildUrl([this.endpoints.backofficeUsers, backofficeUserId]), 'patch', dto);
  }

  getBackofficeUser(backofficeUserId: string): Observable<DataGetBackofficeUserApiResponseDto> {
    return this.request<DataGetBackofficeUserApiResponseDto>(this.buildUrl([this.endpoints.backofficeUsers, backofficeUserId]), 'get');
  }

  // questions

  createQuestion(dto: DataCreateQuestionApiRequestDto): Observable<DataCreateQuestionApiResponseDto> {
    return this.request<DataCreateQuestionApiResponseDto>(this.buildUrl([this.endpoints.questions]), 'post', dto);
  }

  listQuestions(query: QueryListQuestionsApiRequestDto): Observable<DataListQuestionsApiResponseDto> {
    return this.request<DataListQuestionsApiResponseDto>(this.buildUrl([this.endpoints.questions], query), 'get');
  }

  updateQuestion(questionId: string, dto: DataUpdateQuestionApiRequestDto): Observable<DataUpdateQuestionApiResponseDto> {
    return this.request<DataUpdateQuestionApiResponseDto>(this.buildUrl([this.endpoints.questions, questionId]), 'patch', dto);
  }

  getQuestion(questionId: string): Observable<DataGetQuestionApiResponseDto> {
    return this.request<DataGetQuestionApiResponseDto>(this.buildUrl([this.endpoints.questions, questionId]), 'get');
  }

  deleteQuestion(questionId: string): Observable<DataDeleteQuestionApiResponseDto> {
    return this.request<DataDeleteQuestionApiResponseDto>(this.buildUrl([this.endpoints.questions, questionId]), 'delete');
  }

  // question texts

  createQuestionText(questionId: string, dto: DataCreateQuestionTextApiRequestDto): Observable<DataCreateQuestionTextApiResponseDto> {
    return this.request<DataCreateQuestionTextApiResponseDto>(this.buildUrl([this.endpoints.questions, questionId, this.endpoints.questionTexts]), 'post', dto);
  }

  listQuestionTexts(questionId: string, query: QueryListQuestionTextsApiRequestDto): Observable<DataListQuestionTextsApiResponseDto> {
    return this.request<DataListQuestionTextsApiResponseDto>(this.buildUrl([this.endpoints.questions, questionId, this.endpoints.questionTexts], query), 'get');
  }

  updateQuestionText(questionId: string, questionTextId: string, dto: DataUpdateQuestionTextApiRequestDto): Observable<DataUpdateQuestionTextApiResponseDto> {
    return this.request<DataUpdateQuestionTextApiResponseDto>(this.buildUrl([this.endpoints.questions, questionId, this.endpoints.questionTexts, questionTextId]), 'patch', dto);
  }

  getQuestionText(questionId: string, questionTextId: string): Observable<DataGetQuestionTextApiResponseDto> {
    return this.request<DataGetQuestionTextApiResponseDto>(this.buildUrl([this.endpoints.questions, questionId, this.endpoints.questionTexts, questionTextId]), 'get');
  }

  deleteQuestionText(questionId: string, questionTextId: string): Observable<DataDeleteQuestionTextApiResponseDto> {
    return this.request<DataDeleteQuestionTextApiResponseDto>(this.buildUrl([this.endpoints.questions, questionId, this.endpoints.questionTexts, questionTextId]), 'delete');
  }

  // companies

  listCompanies(query: QueryListCompaniesApiRequestDto): Observable<DataListCompaniesApiResponseDto> {
    return this.request<DataListCompaniesApiResponseDto>(this.buildUrl([this.endpoints.companies], query), 'get');
  }

  getCompany(companyId: string): Observable<DataGetCompanyApiResponseDto> {
    return this.request<DataGetCompanyApiResponseDto>(this.buildUrl([this.endpoints.companies, companyId]), 'get');
  }

  // editions

  createEdition(dto: DataCreateEditionApiRequestDto): Observable<DataCreateEditionApiResponseDto> {
    return this.request<DataCreateEditionApiResponseDto>(this.buildUrl([this.endpoints.editions]), 'post', dto);
  }

  listEditions(query: QueryListEditionsApiRequestDto): Observable<DataListEditionsApiResponseDto> {
    return this.request<DataListEditionsApiResponseDto>(this.buildUrl([this.endpoints.editions], query), 'get');
  }

  updateEdition(editionCode: string, dto: DataUpdateEditionApiRequestDto): Observable<DataUpdateEditionApiResponseDto> {
    return this.request<DataUpdateEditionApiResponseDto>(this.buildUrl([this.endpoints.editions, editionCode]), 'patch', dto);
  }

  getEdition(editionCode: string): Observable<DataGetEditionApiResponseDto> {
    return this.request<DataGetEditionApiResponseDto>(this.buildUrl([this.endpoints.editions, editionCode]), 'get');
  }

  deleteEdition(editionCode: string): Observable<DataDeleteEditionApiResponseDto> {
    return this.request<DataDeleteEditionApiResponseDto>(this.buildUrl([this.endpoints.editions, editionCode]), 'delete');
  }

  /*
   * API-PERSONS
   */

  // persons

  createPerson(dto: DataCreatePersonApiRequestDto): Observable<DataCreatePersonApiResponseDto> {
    return this.request<DataCreatePersonApiResponseDto>(this.buildUrl([this.endpoints.persons]), 'post', dto);
  }

  listPersons(query: QueryListPersonsApiRequestDto): Observable<DataListPersonsApiResponseDto> {
    return this.request<DataListPersonsApiResponseDto>(this.buildUrl([this.endpoints.persons], query), 'get');
  }

  updatePerson(personId: string, dto: DataUpdatePersonApiRequestDto): Observable<DataUpdatePersonApiResponseDto> {
    return this.request<DataUpdatePersonApiResponseDto>(this.buildUrl([this.endpoints.persons, personId]), 'patch', dto);
  }

  getPerson(personId: string, query: QueryGetPersonApiRequestDto): Observable<DataGetPersonApiResponseDto> {
    return this.request<DataGetPersonApiResponseDto>(this.buildUrl([this.endpoints.persons, personId], query), 'get');
  }

  // privacy

  createEventPrivacyPerson(personId: string, dto: DataCreatePrivacyEventsApiRequestDto): Observable<DataCreatePrivacyEventsApiResponseDto> {
    return this.request<DataCreatePrivacyEventsApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personPrivacy]), 'post', dto);
  }

  getRegisterPrivacyPerson(personId: string, query: QueryPrivacyRegisterApiRequestDto): Observable<DataPrivacyRegisterApiResponseDto> {
    return this.request<DataPrivacyRegisterApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personPrivacy], query), 'get');
  }

  // profile

  upsertPersonProfile(personId: string, dto: DataUpsertPersonProfileApiRequestDto): Observable<DataUpsertPersonProfileApiResponseDto> {
    return this.request<DataUpsertPersonProfileApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personProfile]), 'put', dto);
  }

  getPersonProfile(personId: string): Observable<DataGetPersonProfileApiResponseDto> {
    return this.request<DataGetPersonProfileApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personProfile]), 'get');
  }

  // contacts

  createPersonContact(personId: string, dto: DataCreatePersonContactApiRequestDto): Observable<DataCreatePersonContactApiResponseDto> {
    return this.request<DataCreatePersonContactApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personContacts]), 'post', dto);
  }

  listPersonContacts(personId: string, query: QueryListPersonContactsApiRequestDto): Observable<DataListPersonContactsApiResponseDto> {
    return this.request<DataListPersonContactsApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personContacts], query), 'get');
  }

  updatePersonContact(personId: string, personContactId: string, dto: DataUpdatePersonContactApiRequestDto): Observable<DataUpdatePersonContactApiResponseDto> {
    return this.request<DataUpdatePersonContactApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personContacts, personContactId]), 'patch', dto);
  }

  getPersonContact(personId: string, personContactId: string): Observable<DataGetPersonContactApiResponseDto> {
    return this.request<DataGetPersonContactApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personContacts, personContactId]), 'get');
  }

  deletePersonContact(personId: string, personContactId: string): Observable<DataDeletePersonContactApiResponseDto> {
    return this.request<DataDeletePersonContactApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personContacts, personContactId]), 'delete');
  }

  // company profiles

  createPersonCompanyProfile(personId: string, dto: DataCreatePersonCompanyProfileApiRequestDto): Observable<DataCreatePersonCompanyProfileApiResponseDto> {
    return this.request<DataCreatePersonCompanyProfileApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personCompanyProfiles]), 'post', dto);
  }

  listPersonCompanyProfiles(personId: string, query: QueryListPersonCompanyProfilesApiRequestDto): Observable<DataListPersonCompanyProfilesApiResponseDto> {
    return this.request<DataListPersonCompanyProfilesApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personCompanyProfiles], query), 'get');
  }

  updatePersonCompanyProfile(
    personId: string,
    personCompanyProfileId: string,
    dto: DataUpdatePersonCompanyProfileApiRequestDto
  ): Observable<DataUpdatePersonCompanyProfileApiResponseDto> {
    return this.request<DataUpdatePersonCompanyProfileApiResponseDto>(
      this.buildUrl([this.endpoints.persons, personId, this.endpoints.personCompanyProfiles, personCompanyProfileId]),
      'patch',
      dto
    );
  }

  getPersonCompanyProfile(personId: string, personCompanyProfileId: string): Observable<DataGetPersonCompanyProfileApiResponseDto> {
    return this.request<DataGetPersonCompanyProfileApiResponseDto>(
      this.buildUrl([this.endpoints.persons, personId, this.endpoints.personCompanyProfiles, personCompanyProfileId]),
      'get'
    );
  }

  deletePersonCompanyProfile(personId: string, personCompanyProfileId: string): Observable<DataDeletePersonCompanyProfileApiResponseDto> {
    return this.request<DataDeletePersonCompanyProfileApiResponseDto>(
      this.buildUrl([this.endpoints.persons, personId, this.endpoints.personCompanyProfiles, personCompanyProfileId]),
      'delete'
    );
  }

  // touchpoint ingresses

  createTouchpointPersonIngress(personId: string, dto: DataCreateTouchpointPersonIngressApiRequestDto): Observable<DataCreateTouchpointPersonIngressApiResponseDto> {
    return this.request<DataCreateTouchpointPersonIngressApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personTouchpointIngresses]), 'post', dto);
  }

  listTouchpointPersonIngresses(personId: string, query: QueryListTouchpointPersonIngressesApiRequestDto): Observable<DataListTouchpointPersonIngressesApiResponseDto> {
    return this.request<DataListTouchpointPersonIngressesApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personTouchpointIngresses], query), 'get');
  }

  getTouchpointPersonIngress(personId: string, touchpointPersonIngressId: string): Observable<DataGetTouchpointPersonIngressApiResponseDto> {
    return this.request<DataGetTouchpointPersonIngressApiResponseDto>(
      this.buildUrl([this.endpoints.persons, personId, this.endpoints.personTouchpointIngresses, touchpointPersonIngressId]),
      'get'
    );
  }

  // answers

  createAnswerPerson(personId: string, dto: DataCreateAnswerApiRequestDto): Observable<DataCreateAnswerApiResponseDto> {
    return this.request<DataCreateAnswerApiResponseDto>(this.buildUrl([this.endpoints.persons, personId, this.endpoints.personAnswers]), 'post', dto);
  }
}
