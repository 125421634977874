import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  private dialog = inject(MatDialog);
  private router = inject(Router);

  constructor() {
    this.openLoginDialog();
  }

  openLoginDialog() {
    this.dialog
      .open(LoginDialogComponent, {
        width: '300px',
      })
      .afterClosed()
      .subscribe(() => {
        this.router.navigate(['/']);
      });
  }
}
