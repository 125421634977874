import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle, MatDialogClose } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { ApiCmdService } from '../../../../services/api-cmd.service';
import { DataCreatePersonContactApiRequestDto } from '@packages/api-cmd-type-definitions';
import { HasRoleModule } from '../../../../modules/has-role.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../../utils/snackbar.handler';
import { CONTACT_TYPES, getContactTypesLabel } from '../../../../constants/contact.constants';
import { MatOption, MatSelect } from '@angular/material/select';
import { NgForOf, NgIf } from '@angular/common';
import { GetPersonContactResponse } from '@packages/api-cmd-type-definitions/dist/person-contacts/get-person-contact.dto';

@Component({
  selector: 'app-contact-create-dialog',
  standalone: true,
  imports: [
    MatLabel,
    MatDialogTitle,
    MatDialogContent,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatCheckbox,
    MatDialogActions,
    MatButton,
    HasRoleModule,
    MatDialogClose,
    MatSelect,
    MatOption,
    NgForOf,
    NgIf,
  ],
  templateUrl: './contact-create-dialog.component.html',
  styleUrl: './contact-create-dialog.component.scss',
})
export class ContactCreateDialogComponent {
  contactForm: FormGroup;
  contactTypes = CONTACT_TYPES.filter(type => !this.data.contacts?.some(contact => contact.contactType === type.value));
  getContactTypesLabel = getContactTypesLabel;
  public isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private apiCmdService: ApiCmdService,
    public dialogRef: MatDialogRef<ContactCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { personId: string; personCompanyProfileId?: string; contacts: GetPersonContactResponse[] },
    private snackbar: MatSnackBar
  ) {
    this.contactForm = this.fb.group({
      contactType: ['', Validators.required],
      contactValue: ['', Validators.required],
      isPreferred: [false],
    });
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      const newContact: DataCreatePersonContactApiRequestDto['data'] = this.contactForm.value;
      this.apiCmdService
        .createPersonContact(this.data.personId, {
          data: {
            ...newContact,
            personCompanyProfileId: this.data.personCompanyProfileId || undefined,
          },
        })
        .subscribe({
          next: () => {
            this.isSubmitting = false;
            this.dialogRef.close(true);
          },
          error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
        });
    }
  }
}
