<div class="filter-container">
  <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">
    <h3>Filtri<button class="close-dialog" mat-button mat-dialog-close>X</button></h3>

    <mat-form-field appearance="outline">
      <mat-label>email</mat-label>
      <input matInput formControlName="email" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="firstName" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Cognome</mat-label>
      <input matInput formControlName="lastName" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Ruolo</mat-label>
      <mat-select formControlName="role" placeholder="Search">
        @for (item of ROLES; track $index) {
          <mat-option [value]="item.value">
            {{ item.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Legal entity</mat-label>
      <mat-select formControlName="legalEntityCodes" placeholder="Search">
        <mat-option *ngFor="let entity of legalEntities" [value]="entity.code">
          {{ entity.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Stato</mat-label>
      <mat-select formControlName="isEnabled" placeholder="Search">
        @for (item of IS_ENABLED; track $index) {
          <mat-option [value]="item.value">
            {{ item.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <br />

    <button mat-raised-button color="primary" type="button" (click)="resetFilters()">
      <mat-icon fontIcon="filter_alt_off"></mat-icon>
      Pulisci filtri
    </button>

    <button mat-raised-button color="primary" type="submit" [disabled]="filterForm.invalid">Applica</button>
  </form>
</div>
