import { Component, computed, inject, OnDestroy, OnInit } from '@angular/core';
import { MatSidenavContent } from '@angular/material/sidenav';
import { MatButton, MatIconButton } from '@angular/material/button';
import { Router, RouterLink } from '@angular/router';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { HasRoleModule } from '../../../modules/has-role.module';
import { MatListItem } from '@angular/material/list';
import { toObservable } from '@angular/core/rxjs-interop';
import { Subscription } from 'rxjs';
import { DataListTouchpointPrivacyConfigurationResponseDto, QueryListTouchpointPrivacyConfigurationRequestDto } from '@packages/api-cmd-type-definitions';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../utils/snackbar.handler';
import { AppStateService } from '../../../services/app-state.service';
import { ApiCmdService } from '../../../services/api-cmd.service';
import { FiltersTouchpointPrivacyConfigurationComponent } from '../filters/filters-touchpoint-privacy-configuration.component';

@Component({
  selector: 'app-touchpoint-privacy-configuration',
  standalone: true,
  imports: [
    MatSidenavContent,
    MatButton,
    RouterLink,
    MatTable,
    MatSort,
    MatHeaderCell,
    MatColumnDef,
    MatCell,
    MatHeaderRow,
    MatRow,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRowDef,
    MatRowDef,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    MatSortHeader,
    MatIconButton,
    MatIcon,
    MatProgressSpinner,
    NgIf,
    HasRoleModule,
    MatListItem,
  ],
  templateUrl: './list-touchpoint-privacy-configurations.component.html',
  styleUrl: './list-touchpoint-privacy-configurations.component.scss',
})
export class ListTouchpointPrivacyConfigurationsComponent implements OnInit, OnDestroy {
  public router = inject(Router);
  public appStateService = inject(AppStateService);
  private apiCmdService = inject(ApiCmdService);
  displayedColumns: string[] = ['touchpointCode', 'exhibitionCode', 'exhibitionGroupCode', 'tgTouchpointConfigCode', 'description'];
  dataSource = new MatTableDataSource<any>([]);
  paginationSkip = 0;
  paginationLimit = 32;
  sortField = 'touchpointCode';
  sortDirection: 'asc' | 'desc' = 'desc';
  public $legalEntityCodeSelected = toObservable(computed(() => this.appStateService.$legalEntityCodeSelected())).subscribe({
    next: value => {
      this.clearTable();
      this.loadData();
    },
    error: err => {
      console.error(err);
    },
  });
  private scrollSubscription: Subscription = new Subscription();
  private queryFilters: QueryListTouchpointPrivacyConfigurationRequestDto = {}; //todo: verificare il nome
  public isLoading = true;

  constructor(
    public dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  private setterIsLoading(value: boolean): () => void {
    return () => {
      this.isLoading = value;
    };
  }

  ngOnInit() {
    //this.loadData();
  }

  ngOnDestroy() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
    this.resetFilters();
    this.resetSort();
    this.clearTable();
  }

  navigateToCreate() {
    this.router.navigate(['/configurazioni-tp/create']);
  }

  refreshList() {
    this.clearTable();
    this.loadData();
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(FiltersTouchpointPrivacyConfigurationComponent, { data: this.queryFilters }); // todo: inserire i nomi giusti

    dialogRef.componentInstance.filtersApplied.subscribe((filters: any) => {
      this.applyFilters(filters);
      if (Object.keys(filters).length > 0) {
        this.clearTable();
        this.loadData();
      }
    });
  }

  clearTable() {
    this.paginationSkip = 0;
    this.dataSource.data = [];
  }
  // todo
  resetFilters() {}
  // todo
  resetSort() {
    this.sortField = 'touchpointCode';
    this.sortDirection = 'desc';
  }

  applyFilters(filters: Record<string, any>): void {
    Object.keys(filters).forEach(attribute => {
      if (filters[attribute] === null || filters[attribute] === undefined || filters[attribute] === '') {
        // @ts-expect-error TS7053
        delete this.queryFilters[attribute];
      } else {
        // @ts-expect-error TS7053
        this.queryFilters[attribute] = filters[attribute];
      }
    });
  }

  loadData() {
    this.isLoading = true;
    this.apiCmdService
      .listTouchpointPrivacyConfig({
        ...this.queryFilters,
        paginationSkip: this.paginationSkip,
        paginationLimit: this.paginationLimit,
        sortOrder: this.sortDirection,
        fieldOrder: this.sortField,
      })
      .subscribe({
        next: (response: DataListTouchpointPrivacyConfigurationResponseDto) => {
          this.dataSource.data = [...this.dataSource.data, ...response.data];
          this.isLoading = false;
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
      });
  }

  loadMoreData(event: any) {
    this.paginationSkip += this.paginationLimit;
    this.loadData();
  }

  sortData(sort: Sort) {
    this.sortField = sort.active;
    this.sortDirection = sort.direction || 'asc';
    this.clearTable();
    this.loadData();
  }

  onRowClick(event: MouseEvent, row: any) {
    if (window.getSelection()?.toString()) {
      return; // Do nothing if text is selected
    }

    this.router.navigate(['/configurazioni-tp/edit', row.id]);
  }

  onMouseUp(event: MouseEvent) {
    // This method is just to capture the mouseup event
  }
}
