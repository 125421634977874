import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SessionStorageService } from '../services/session-storage.service';

@Directive({
  selector: '[appHasRole]',
})
export class HasRoleDirective {
  private readonly currentUserRole: string | null = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sessionStorageService: SessionStorageService
  ) {
    const profile = this.sessionStorageService.getBackofficeUserProfile();
    this.currentUserRole = profile ? profile.role : null;
  }

  @Input() set appHasRole(roles: string[]) {
    if (roles.some(role => `roles/${role}` === this.currentUserRole)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
