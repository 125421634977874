import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { HasRoleModule } from '../../../modules/has-role.module';

@Component({
  selector: 'app-edtions-filters',
  standalone: true,
  imports: [FormsModule, MatButton, MatFormField, MatIcon, MatInput, MatLabel, ReactiveFormsModule, HasRoleModule, MatDialogClose],
  templateUrl: './editions-filters.component.html',
  styleUrl: './editions-filters.component.scss',
})
export class EditionsFiltersComponent {
  filterForm: FormGroup;
  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any, // Inject the passed data (filters)
    private dialogRef: MatDialogRef<EditionsFiltersComponent>
  ) {
    this.filterForm = this.fb.group({
      editionCode: [data?.editionCode || ''],
      editionName: [data?.editionName || ''],
      exhibitionCode: [data?.exhibitionCode || ''],
      exhibitionName: [data?.exhibitionName || ''],
      exhibitionGroupCode: [data?.exhibitionGroupCode || ''],
      exhibitionGroupName: [data?.exhibitionGroupName || ''],
    });
  }

  applyFilters() {
    const filterValues = this.filterForm.value;
    this.filtersApplied.emit(filterValues);
    this.dialogRef.close(filterValues);
  }

  resetFilters() {
    this.filterForm.reset();
    this.filtersApplied.emit({});
  }
}
