<div class="container">
  <div class="header">
    <button mat-raised-button color="primary" (click)="openFilterDialog()"><mat-icon fontIcon="search"></mat-icon>Ricerca</button>
    <button mat-raised-button color="primary" (click)="refreshList()">
      <mat-icon fontIcon="refresh"></mat-icon>
    </button>
    <button *appHasRole="['owner']" mat-raised-button color="primary" (click)="navigateToCreate()">Crea utente</button>
  </div>

  <cdk-virtual-scroll-viewport itemSize="50" class="viewport" (scrollend)="loadMoreData($event)">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8">
      <!--Row selection and clickable -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <!--        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)"></mat-row>-->
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick($event, row)" (mouseup)="onMouseUp($event)"></mat-row>
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header sticky> E-mail </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.email }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Nome </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.firstName }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Cognome </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.lastName }} </span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Ruolo </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ getRoleLabel(element.role) }} </span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="isEnabled">
        <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Stato </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ getIsEnabledLabel(element.isEnabled) }}</span></mat-cell
        >
      </ng-container>
    </mat-table>
  </cdk-virtual-scroll-viewport>

  <div *ngIf="isLoading" class="loader-container">
    <mat-spinner></mat-spinner>
  </div>
</div>
