import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCmdService } from '../../../services/api-cmd.service';
import { DataUpdateEditionApiRequestDto } from '@packages/api-cmd-type-definitions';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { GetEditionResponse } from '@packages/api-cmd-type-definitions/lib/editions/get-edition.dto';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { HasRoleModule } from '../../../modules/has-role.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../utils/snackbar.handler';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-edit-edition',
  standalone: true,
  templateUrl: './edit-edition.component.html',
  styleUrls: ['./edit-edition.component.scss'],
  imports: [MatLabel, MatIcon, MatButton, MatCard, MatCardContent, ReactiveFormsModule, MatFormField, MatInput, MatIconButton, HasRoleModule, MatProgressSpinner, NgIf],
})
export class EditEditionComponent implements OnInit {
  editionForm: FormGroup;
  edition: GetEditionResponse | undefined;
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private fb = inject(FormBuilder);
  private apiCmdService = inject(ApiCmdService);
  public isLoading = true;
  public isSubmitting = false;

  constructor(
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {
    this.editionForm = this.fb.group({
      editionName: ['', Validators.required],
      exhibitionName: ['', Validators.required],
      exhibitionGroupName: ['', Validators.required],
      exhibitionGroupCode: ['', Validators.required],
    });
  }

  private setterIsLoading(value: boolean): () => void {
    return () => {
      this.isLoading = value;
    };
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  ngOnInit(): void {
    this.loadEdition();
  }

  loadEdition(): void {
    const editionCode = this.route.snapshot.paramMap.get('editionCode');
    if (editionCode) {
      this.isLoading = true;
      this.apiCmdService.getEdition(editionCode).subscribe({
        next: response => {
          this.edition = response.data;
          this.editionForm.patchValue(this.edition);
          this.isLoading = false;
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
      });
    }
  }

  onSubmit(): void {
    if (this.editionForm.valid && this.edition) {
      this.isSubmitting = true;
      const updateDto: DataUpdateEditionApiRequestDto['data'] = this.editionForm.value;
      this.apiCmdService.updateEdition(this.edition.editionCode, { data: updateDto }).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.loadEdition();
        },
        error: err => handlerSnackbarApiError(this.snackbar, err,[this.setterIsSubmitting(false)]),
      });
    }
  }

  deleteEdition(): void {
    if (this.edition) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.edition) {
            this.apiCmdService.deleteEdition(this.edition.editionCode).subscribe({
              next: () => {
                this.navigateBack();
              },
              error: err => handlerSnackbarApiError(this.snackbar, err),
            });
          }
        }
      });
    }
  }

  navigateBack(): void {
    this.router.navigate(['/edizioni']);
  }
}
