import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HasRoleModule } from '../../../modules/has-role.module';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { DataUpdateTouchpointPrivacyConfigurationRequestDto, GetTouchpointPrivacyConfiguration } from '@packages/api-cmd-type-definitions';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCmdService } from '../../../services/api-cmd.service';
import { handlerSnackbarApiError } from '../../../utils/snackbar.handler';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-edit-touchpoint-privacy-configuration',
  standalone: true,
  imports: [
    FormsModule,
    HasRoleModule,
    MatButton,
    MatCard,
    MatCardContent,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatProgressSpinner,
    NgIf,
    ReactiveFormsModule,
    MatCheckbox,
  ],
  templateUrl: './edit-touchpoint-privacy-configuration.component.html',
  styleUrl: './edit-touchpoint-privacy-configuration.component.scss',
})
export class EditTouchpointPrivacyConfigurationComponent {
  formGroup: FormGroup;
  tpConfig: GetTouchpointPrivacyConfiguration | undefined;
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private fb = inject(FormBuilder);
  private apiCmdService = inject(ApiCmdService);
  public isLoading = true;
  public isSubmitting = false;

  constructor(
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {
    this.formGroup = this.fb.group({
      // nel form metto i campi che voglio modificare
      description: [''],
      tgTouchpointConfigCode: ['', Validators.required],
      validate: [false],
    });
  }

  private setterIsLoading(value: boolean): () => void {
    return () => {
      this.isLoading = value;
    };
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  ngOnInit(): void {
    this.loadTouchpointPrivacyConfig();
  }

  loadTouchpointPrivacyConfig(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.isLoading = true;
      this.apiCmdService.getTouchpointPrivacyConfig(id).subscribe({
        next: response => {
          this.tpConfig = response.data;
          this.formGroup.patchValue(this.tpConfig);
          this.isLoading = false;
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
      });
    }
  }

  onSubmit(): void {
    if (this.formGroup.valid && this.tpConfig) {
      this.isSubmitting = true;
      const updateDto: DataUpdateTouchpointPrivacyConfigurationRequestDto['data'] = {
        description: this.formGroup.value.description === '' ? null : this.formGroup.value.description,
        tgTouchpointConfigCode: this.formGroup.value.tgTouchpointConfigCode || undefined,
        validate: !this.formGroup.value.validate,
      };
      Object.keys(updateDto).forEach(key => {
        // @ts-expect-error TS7053
        if (updateDto[key] === '') updateDto[key] = null;
      });
      this.apiCmdService.updateTouchpointPrivacyConfig(this.tpConfig.id, { data: updateDto }).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.loadTouchpointPrivacyConfig();
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
      });
    }
  }

  deleteEdition(): void {
    if (this.tpConfig) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.tpConfig) {
            this.apiCmdService.deleteTouchpointPrivacyConfig(this.tpConfig.id).subscribe({
              next: () => {
                this.navigateBack();
              },
              error: err => handlerSnackbarApiError(this.snackbar, err),
            });
          }
        }
      });
    }
  }

  navigateBack(): void {
    this.router.navigate(['/configurazioni-tp']);
  }
}
