<h1 mat-dialog-title>
  Conferma eliminazione
  <button mat-button mat-dialog-close>X</button>
</h1>
<div mat-dialog-content>
  <p>Sei sicuro di voler eliminare?</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Annulla</button>
  <button mat-button (click)="onConfirm()">Conferma</button>
</div>
