import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-filters-privacy-configuration',
  standalone: true,
  imports: [FormsModule, MatButton, MatFormField, MatIcon, MatInput, MatLabel, ReactiveFormsModule, MatDialogClose],
  templateUrl: './filters-touchpoint-privacy-configuration.component.html',
  styleUrl: './filters-touchpoint-privacy-configuration.component.scss',
})
export class FiltersTouchpointPrivacyConfigurationComponent {
  filterForm: FormGroup;
  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any, // Inject the passed data (filters)
    private dialogRef: MatDialogRef<FiltersTouchpointPrivacyConfigurationComponent>
  ) {
    this.filterForm = this.fb.group({
      touchpointCode: [data?.touchpointCode || ''],
      exhibitionCode: [data?.exhibitionCode || ''],
      exhibitionGroupCode: [data?.exhibitionGroupCode || ''],
      tgTouchpointConfigCode: [data?.tgTouchpointConfigCode || ''],
    });
  }

  applyFilters() {
    const filterValues = this.filterForm.value;
    this.filtersApplied.emit(filterValues);
    this.dialogRef.close(filterValues);
  }

  resetFilters() {
    this.filterForm.reset();
    this.filtersApplied.emit({});
  }
}
