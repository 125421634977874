import { computed, Injectable, signal } from '@angular/core';
import { LegalEntity } from '../types/legal-entity.type';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  // loader
  #$loading = signal<boolean>(false);
  public $loading = computed(this.#$loading);
  #$legalEntityCodeSelected = signal<LegalEntity>({ code: '', name: '' }); // legal entity selezionata da dropdown
  public $legalEntityCodeSelected = computed(this.#$legalEntityCodeSelected);

  public setLoading(value: boolean) {
    this.#$loading.set(value);
  }
  public setLegalEntityCodeSelected(value: LegalEntity) {
    this.#$legalEntityCodeSelected.set(value);
  }
}
