<div class="container">
  <div class="header">
    <button mat-raised-button color="primary" (click)="openFilterDialog()"><mat-icon fontIcon="search"></mat-icon>Ricerca</button>
    <button mat-raised-button color="primary" (click)="refreshList()">
      <mat-icon fontIcon="refresh"></mat-icon>
    </button>
    <button *appHasRole="['editor', 'admin', 'owner']" mat-raised-button color="primary" (click)="navigateToCreate()">Crea edizione</button>
  </div>

  <cdk-virtual-scroll-viewport itemSize="50" class="viewport" (scrollend)="loadMoreData($event)">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8">
      <!--Row selection and clickable -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <!--        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)"></mat-row>-->
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick($event, row)" (mouseup)="onMouseUp($event)"></mat-row>
      <ng-container matColumnDef="editionCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Codice edizione </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.editionCode }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="editionName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Edizione </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.editionName }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="exhibitionCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Codice manifestazione </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.exhibitionCode }} </span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="exhibitionName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Manifestazione </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.exhibitionName }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="exhibitionGroupCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Codice macromanifestazione </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.exhibitionGroupCode }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="exhibitionGroupName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Macromanifestazione </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.exhibitionGroupName }} </span></mat-cell
        >
      </ng-container>
    </mat-table>
  </cdk-virtual-scroll-viewport>

  <div *ngIf="isLoading" class="loader-container">
    <mat-spinner></mat-spinner>
  </div>
</div>
