import { Component, Inject, OnInit } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-snackbar',
  standalone: true,
  imports: [MatProgressBar, MatIconButton, MatIcon],
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.scss',
})
export class SnackbarComponent implements OnInit {
  progress = 0;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<SnackbarComponent>
  ) {}

  ngOnInit(): void {
    const duration = this.data.duration || 5e3;
    const interval = 1e2;
    const step = (interval / duration) * 1e2;

    const timer = setInterval(() => {
      this.progress += step;
      if (this.progress >= 1e2) {
        clearInterval(timer);
      }
    }, interval);
  }

  closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }
}
