export const ROLES = [
  { label: 'Viewer', value: 'roles/viewer' },
  { label: 'Editor', value: 'roles/editor' },
  { label: 'Admin', value: 'roles/admin' },
  { label: 'Owner', value: 'roles/owner' },
];
export function getRoleLabel(value: string): string {
  const item = ROLES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

export const IS_ENABLED = [
  { label: 'Attivo', value: true },
  { label: 'Disattivo', value: false },
];
export function getIsEnabledLabel(value: boolean): string {
  const item = IS_ENABLED.find(r => r.value === value);
  return item ? item.label : 'Unknown';
}
