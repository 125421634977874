import { Component, inject } from '@angular/core';
import { MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { MatButton } from '@angular/material/button';
import { AuthAppService } from '../../services/auth-app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-dialog',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton],
  templateUrl: './login-dialog.component.html',
  styleUrl: './login-dialog.component.scss',
})
export class LoginDialogComponent {
  private dialogRef = inject(MatDialogRef<LoginDialogComponent>);
  private authAppService = inject(AuthAppService);

  get text(): string {
    const activeAccount = this.authAppService.getActiveAccount();
    return activeAccount ? `Utente loggato: ${activeAccount.username}` : 'È necessario effettuare il login SSO nel tenant Fiera Milano';
  }

  get buttonText(): string {
    const activeAccount = this.authAppService.getActiveAccount();
    return activeAccount ? `Chiudi` : 'Login';
  }

  onClick() {
    const activeAccount = this.authAppService.getActiveAccount();
    if (activeAccount) {
      this.dialogRef.close();
      return;
    }
    this.authAppService.login('/').subscribe({
      next: () => {
        console.log('login success');
        this.dialogRef.close();
      },
      error: error => {
        console.error(error);
      },
    });
  }
}
