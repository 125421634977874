<h1 mat-dialog-title>
  Modifica contatto
  <button mat-button mat-dialog-close>X</button>
</h1>
<div mat-dialog-content>
  <form [formGroup]="contactForm">
    <mat-form-field>
      <mat-label>Tipo</mat-label>
      <mat-select formControlName="contactType" required>
        <mat-option *ngIf="contactTypes.length === 0" disabled>
          Tutti i tipi di contatto sono stati aggiunti
        </mat-option>
        <mat-option *ngFor="let type of contactTypes" [value]="type.value">
          {{ type.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Valore</mat-label>
      <input matInput formControlName="contactValue" required />
    </mat-form-field>

    <mat-checkbox formControlName="isPreferred">Preferito</mat-checkbox>
  </form>
</div>
<div mat-dialog-actions>
  <br />

  <button mat-button (click)="onCancel()">Annulla</button>
  <button *appHasRole="['admin', 'owner']" mat-button (click)="onDelete()">Elimina</button>
  <!--  <button *appHasRole="['editor','admin','owner']" mat-button (click)="onSubmit()" [disabled]="contactForm.invalid || isLoading">Salva</button>-->
  <button *appHasRole="['editor', 'admin', 'owner']" mat-button (click)="onSubmit()" [disabled]="contactForm.invalid || isLoading || isSubmitting">
    <ng-container *ngIf="!isSubmitting">Salva</ng-container>
    <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
  </button>
</div>

<div *ngIf="isLoading" class="loader-container">
  <mat-spinner></mat-spinner>
</div>
