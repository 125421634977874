import { Component, inject, OnInit } from '@angular/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCmdService } from '../../../services/api-cmd.service';
import { GetBackofficeUserResponse } from '@packages/api-cmd-type-definitions/lib/backoffice-users/get-backoffice-user.dto';
import { DataUpdateBackofficeUserApiRequestDto } from '@packages/api-cmd-type-definitions';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { NgForOf, NgIf } from '@angular/common';
import { IS_ENABLED, ROLES } from '../../../constants/backoffice-user.constants';
import { SessionStorageService } from '../../../services/session-storage.service';
import { LegalEntity } from '../../../types/legal-entity.type';
import { HasRoleModule } from '../../../modules/has-role.module';
import { handlerSnackbarApiError } from '../../../utils/snackbar.handler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-edit-backoffice-user',
  standalone: true,
  imports: [
    MatLabel,
    MatIcon,
    MatButton,
    MatCard,
    MatCardContent,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatIconButton,
    MatError,
    MatOption,
    MatSelect,
    NgForOf,
    HasRoleModule,
    MatProgressSpinner,
    NgIf,
  ],
  templateUrl: './edit-backoffice-user.component.html',
  styleUrl: './edit-backoffice-user.component.scss',
})
export class EditBackofficeUserComponent implements OnInit {
  backofficeUserForm: FormGroup;
  backofficeUser: GetBackofficeUserResponse | undefined;
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private fb = inject(FormBuilder);
  private apiCmdService = inject(ApiCmdService);
  protected readonly ROLES = ROLES;
  protected readonly IS_ENABLED = IS_ENABLED;
  legalEntities: LegalEntity[] = this.sessionStorageService.getLegalEntities() || [];
  public isLoading = true;
  public isSubmitting = false;

  constructor(
    private sessionStorageService: SessionStorageService,
    private snackbar: MatSnackBar
  ) {
    this.backofficeUserForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      role: ['', Validators.required],
      legalEntityCodes: this.fb.array([], [Validators.required, Validators.minLength(1)]),
      isEnabled: ['', Validators.required],
    });
  }

  private setterIsLoading(value: boolean): () => void {
    return () => {
      this.isLoading = value;
    };
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  ngOnInit(): void {
    this.loadBackofficeUser();
  }

  loadBackofficeUser(): void {
    const backofficeUserId = this.route.snapshot.paramMap.get('email');
    if (backofficeUserId) {
      this.isLoading = true;
      this.apiCmdService.getBackofficeUser(backofficeUserId).subscribe({
        next: response => {
          this.backofficeUser = response.data;
          this.backofficeUserForm.patchValue(this.backofficeUser);
          this.setLegalEntityCodes(this.backofficeUser.legalEntityCodes);
          this.isLoading = false;
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
      });
    }
  }

  setLegalEntityCodes(legalEntityCodes: string[]): void {
    const legalEntityFormGroups = legalEntityCodes.map(code => this.fb.group({ legalEntityCode: [code, Validators.required] }));
    const legalEntityFormArray = this.fb.array(legalEntityFormGroups);
    this.backofficeUserForm.setControl('legalEntityCodes', legalEntityFormArray);
  }

  get legalEntityCodes(): FormArray {
    return this.backofficeUserForm.get('legalEntityCodes') as FormArray;
  }

  addLegalEntityCode(): void {
    this.legalEntityCodes.push(this.fb.group({ legalEntityCode: ['', Validators.required] }));
  }

  removeLegalEntityCode(index: number): void {
    this.legalEntityCodes.removeAt(index);
  }

  onSubmit(): void {
    if (this.backofficeUserForm.valid && this.backofficeUser) {
      this.isSubmitting = true;
      const updateDto: DataUpdateBackofficeUserApiRequestDto['data'] = this.backofficeUserForm.value;
      updateDto.legalEntityCodes = this.legalEntityCodes.controls.map(item => item.value.legalEntityCode);
      this.apiCmdService.updateBackofficeUser(this.backofficeUser.email, { data: updateDto }).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.loadBackofficeUser();
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
      });
    }
  }

  navigateBack(): void {
    this.router.navigate(['/utenti-backoffice']);
  }
}
