<div class="filter-container">
  <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">
    <h3>Filtri<button class="close-dialog" mat-button mat-dialog-close>X</button></h3>

    <mat-form-field appearance="outline">
      <mat-label>Codice</mat-label>
      <input matInput formControlName="code" placeholder="Ricerca Codice completo" />
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Tipo risposta</mat-label>
      <mat-select formControlName="answerType" placeholder="Search">
        <mat-option *ngFor="let type of answerTypes" [value]="type.value">
          {{ type.label }}
        </mat-option>
      </mat-select>    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Nr massimo risposte</mat-label>
      <input matInput formControlName="maxAnswers" placeholder="Ricerca numero esatto" />
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>Testo domanda</mat-label>
      <input matInput formControlName="questionTexts" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />

    <mat-form-field appearance="outline">
      <mat-label>ID</mat-label>
      <input matInput formControlName="id" placeholder="Ricerca ID completo (uuid)" />
    </mat-form-field>

    <br />

    <button mat-raised-button color="primary" (click)="resetFilters()">
      <mat-icon fontIcon="filter_alt_off"></mat-icon>
      Pulisci filtri
    </button>
    <button mat-raised-button color="primary" type="submit" [disabled]="filterForm.invalid">Applica</button>
  </form>
</div>
