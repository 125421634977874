<div class="container">
  <div class="header">
    <button mat-raised-button color="primary" (click)="openFilterDialog()"><mat-icon fontIcon="search"></mat-icon>Ricerca</button>
    <button mat-raised-button color="primary" (click)="refreshList()">
      <mat-icon fontIcon="refresh"></mat-icon>
    </button>
  </div>

  <cdk-virtual-scroll-viewport itemSize="50" class="viewport" (scrollend)="loadMoreData($event)">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8">
      <!--Row selection and clickable -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <!--        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)"></mat-row>-->
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick($event, row)" (mouseup)="onMouseUp($event)"></mat-row>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Ragione sociale </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.name }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="vatNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Partita IVA </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.vatNumber }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="website">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Sito web </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.website }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="countryIso2">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Nazione (ISO 2) </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.countryIso2 }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="agemCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Codice AGEM </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.agemCode }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="numberEmployees">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Nr dipendenti </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.numberEmployees }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="sales">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Fatturato annuo </mat-header-cell>
        <mat-cell *matCellDef="let element" class="selectable"
          ><span>{{ element.sales }}</span></mat-cell
        >
      </ng-container>
    </mat-table>
  </cdk-virtual-scroll-viewport>

  <div *ngIf="isLoading" class="loader-container">
    <mat-spinner></mat-spinner>
  </div>
</div>
