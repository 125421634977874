import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { NgForOf, NgIf } from '@angular/common';
import { ApiCmdService } from '../../../services/api-cmd.service';
import { Router } from '@angular/router';
import { ROLES } from '../../../constants/backoffice-user.constants';
import { LegalEntity } from '../../../types/legal-entity.type';
import { SessionStorageService } from '../../../services/session-storage.service';
import { HasRoleModule } from '../../../modules/has-role.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../utils/snackbar.handler';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-create-backoffice-user',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatCard,
    MatError,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    ReactiveFormsModule,
    HasRoleModule,
    MatProgressSpinner,
    NgIf,
  ],
  templateUrl: './create-backoffice-user.component.html',
  styleUrl: './create-backoffice-user.component.scss',
})
export class CreateBackofficeUserComponent {
  creationForm: FormGroup;
  protected readonly ROLES = ROLES;
  legalEntities: LegalEntity[] = this.sessionStorageService.getLegalEntities() || [];
  public isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private apiCmdService: ApiCmdService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private snackbar: MatSnackBar
  ) {
    this.creationForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      role: ['', Validators.required],
      legalEntityCodes: this.fb.array([this.fb.group({ legalEntityCode: ['', Validators.required] })], [Validators.required, Validators.minLength(1)]),
    });
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  get legalEntityCodes(): FormArray {
    return this.creationForm.get('legalEntityCodes') as FormArray;
  }

  addLegalEntityCode(): void {
    this.legalEntityCodes.push(this.fb.group({ legalEntityCode: ['', Validators.required] }));
  }

  removeLegalEntityCode(index: number): void {
    this.legalEntityCodes.removeAt(index);
  }

  submitForm() {
    if (this.creationForm.valid) {
      this.isSubmitting = true;
      this.apiCmdService
        .createBackofficeUser({
          data: {
            email: this.creationForm.value['email'],
            firstName: this.creationForm.value['firstName'],
            lastName: this.creationForm.value['lastName'],
            role: this.creationForm.value['role'],
            legalEntityCodes: this.creationForm.value['legalEntityCodes'].map((code: any) => code.legalEntityCode),
          },
        })
        .subscribe({
          next: response => {
            this.isSubmitting = false;
            const backofficeUserEmail = response.data;
            this.router.navigate(['/utenti-backoffice/edit', backofficeUserEmail]);
          },
          error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
        });
    } else {
      // todo: mostra errori ?
    }
  }

  navigateBack() {
    this.router.navigate(['/utenti-backoffice']);
  }
}
