<div class="top-bar">
  <button mat-icon-button (click)="navigateBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span class="page-title">{{edition?.editionName}} - {{edition?.editionCode}}</span>
</div>

<mat-card class="scrollable-card">
  <mat-card-content>
    <form [formGroup]="editionForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Nome edizione</mat-label>
        <input matInput formControlName="editionName" required>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Codice edizione</mat-label>
        <input matInput [value]="edition?.editionCode" required readonly disabled>
      </mat-form-field>

      <br>

      <mat-form-field appearance="fill">
        <mat-label>Nome manifestazione</mat-label>
        <input matInput formControlName="exhibitionName" required>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Codice manifestazione</mat-label>
        <input matInput [value]="edition?.exhibitionCode" required readonly disabled>
      </mat-form-field>

      <br>

      <mat-form-field appearance="fill">
        <mat-label>Nome macromanifestazione</mat-label>
        <input matInput formControlName="exhibitionGroupName" required>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Codice macromanifestazione</mat-label>
        <input matInput formControlName="exhibitionGroupCode" required>
      </mat-form-field>

      <br>

      <button *appHasRole="['admin','owner']" mat-raised-button color="warn" type="button" (click)="deleteEdition()">Elimina</button>
<!--      <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" type="submit" [disabled]="editionForm.invalid || isLoading">Salva</button>-->
      <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" type="submit" [disabled]="editionForm.invalid || isSubmitting">
        <ng-container *ngIf="!isSubmitting">Salva</ng-container>
        <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
      </button>

    </form>
  </mat-card-content>
</mat-card>

<div *ngIf="isLoading" class="loader-container">
  <mat-spinner></mat-spinner>
</div>