export const environment = {
  apiUrl: process.env['APP_API_URL'] as `https://${string}`,
  url: process.env['APP_URL'] as string,
  sso: {
    clientId: process.env['APP_SSO_CLIENT_ID'] as string,
    authority: process.env['APP_SSO_AUTHORITY'] as string,
    redirectUri: process.env['APP_SSO_REDIRECT_URI'] as string,
    loginUrl: process.env['APP_SSO_LOGIN_URL'] as string,
  },
};
