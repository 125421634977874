import { Inject, inject, Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';
import { JwtCmd } from '../types/jwt-cmd.type';
import { handlerSnackbarApiError } from '../utils/snackbar.handler';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCmdService } from './api-cmd.service';
import { JwtService } from './jwt.service';
import { BackofficeUserProfile } from '../types/backoffice-user.type';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { environment } from '../../environments/environment';
import { LegalEntity } from '../types/legal-entity.type';
import { Observable } from 'rxjs';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class AuthAppService {
  private snackbar = inject(MatSnackBar);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private apiCmdService: ApiCmdService = inject(ApiCmdService);
  private jwtService = inject(JwtService);
  private sessionStorageService = inject(SessionStorageService);
  private readonly SAML_RESPONSE = 'saml_response';
  private samlResponse: string = '';
  backofficeUserProfile: BackofficeUserProfile | undefined;
  private msalService = inject(MsalService);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
    //private broadcastService: MsalBroadcastService,
    //private msalService: MsalService
  ) {}

  get legalEntities(): LegalEntity[] {
    return (
      this.sessionStorageService
        .getBackofficeUserProfile()
        ?.legalEntityCodes.map((legalEntityCode: string) => {
          const legalEntitiesList = this.sessionStorageService.getLegalEntities() || [];
          return legalEntitiesList.find(legalEntity => legalEntity.code === legalEntityCode);
        })
        // @ts-expect-error TS2769
        .filter((l: LegalEntity) => l) || []
    );
  }

  processSamlResponse(samlResponse: string): string {
    return decodeURIComponent(samlResponse);
  }

  logout() {
    console.log('logout');
    //this.samlResponse = '';
    this.msalService.logout().subscribe({
      next: () => {
        this.msalService.instance.setActiveAccount(null);
        this.sessionStorageService.clearAll();
        this.router.navigate(['/login']);
      },
      error: error => {
        console.error('logout error', error);
      },
    });
  }

  public getActiveAccount(): AccountInfo | null {
    // console.log(this.msalService.instance.getActiveAccount());
    return this.msalService.instance.getActiveAccount();
  }

  public login(redirectPage?: `/${string}`): Observable<void> {
    console.log('login');
    if (!this.msalService.instance.getActiveAccount()) return this.msalService.initialize();
    return this.msalService.loginRedirect({ redirectStartPage: redirectPage || '/', scopes: [] });
  }

  public evaluate(): void {
    this.msalService.handleRedirectObservable().subscribe({
      next: result => {
        if (result) {
          this.msalService.instance.setActiveAccount(result.account);
          if (!result.idToken)
            this.login('/').subscribe({
              next: () => {
                console.log('login success');
              },
              error: error => {
                console.error(error);
              },
            });
          else {
            this.sessionStorageService.setIdToken(result.idToken);
          }
        } else if (this.jwtService.isExpired(this.sessionStorageService.getIdToken())) {
          this.login('/').subscribe({
            next: () => {
              console.log('login success');
            },
            error: error => {
              console.error(error);
            },
          });
        }
        this.apiCmdService.getTokenJwtFromIdToken(this.sessionStorageService.getIdToken()).subscribe({
          next: tokenJwtResponse => {
            // jwt
            const jwt = tokenJwtResponse.data;
            const jwtDecoded = this.jwtService.decode<JwtCmd>(jwt);
            if (!jwtDecoded) {
              console.error('invalid JWT');
              this.login('/').subscribe({
                next: () => {
                  console.log('login success');
                },
                error: error => {
                  console.error(error);
                },
              });
            } else {
              this.backofficeUserProfile = {
                email: jwtDecoded.claims.email,
                role: jwtDecoded.claims.role,
                legalEntityCodes: jwtDecoded.claims.legalEntityCodes,
                firstName: jwtDecoded.claims.firstName,
                lastName: jwtDecoded.claims.lastName,
              };
              this.sessionStorageService.setBackofficeUserProfile(this.backofficeUserProfile);
              this.sessionStorageService.setTokenJwt(jwt);
              // legal entities
              this.apiCmdService.listLegalEntities().subscribe({
                next: legalEntitiesResponse => {
                  this.sessionStorageService.setLegalEntities(legalEntitiesResponse.data);
                  if (result) window.location.reload();
                },
                error: err => handlerSnackbarApiError(this.snackbar, err),
              });
            }
          },
          error: err => {
            handlerSnackbarApiError(this.snackbar, err);
            //this.initialize();
            this.logout();
          },
        });
      },
      error: error => {
        console.error('login error', error);
      },
    });
  }
}
