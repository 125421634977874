import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle, MatDialogClose } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { ApiCmdService } from '../../../../services/api-cmd.service';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/confirmation-dialog.component';
import { DataUpdatePersonCompanyProfileApiRequestDto } from '@packages/api-cmd-type-definitions';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { GetPersonCompanyProfileResponse } from '@packages/api-cmd-type-definitions/dist/person-company-profile/get-person-company-profile.dto';
import { ContactEditDialogComponent } from '../contact-edit-dialog/contact-edit-dialog.component';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatCheckbox } from '@angular/material/checkbox';
import { ContactCreateDialogComponent } from '../contact-create-dialog/contact-create-dialog.component';
import { HasRoleModule } from '../../../../modules/has-role.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../../utils/snackbar.handler';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { CONTACT_TYPES, getContactTypesLabel } from '../../../../constants/contact.constants';

@Component({
  selector: 'app-company-profile-edit-dialog',
  standalone: true,
  imports: [
    MatLabel,
    MatDialogTitle,
    MatDialogContent,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatDialogActions,
    MatButton,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRow,
    MatRow,
    MatHeaderRowDef,
    MatRowDef,
    MatCheckbox,
    HasRoleModule,
    MatProgressSpinner,
    NgIf,
    MatDialogClose,
  ],
  templateUrl: './company-profile-edit-dialog.component.html',
  styleUrl: './company-profile-edit-dialog.component.scss',
})
export class CompanyProfileEditDialogComponent {
  companyProfileForm: FormGroup;
  newContactForm: FormGroup;
  personContacts: GetPersonCompanyProfileResponse['personContacts'] = [];
  contactTypes = CONTACT_TYPES;
  getContactTypesLabel = getContactTypesLabel;
  public isLoading = true;
  public isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private apiCmdService: ApiCmdService,
    public dialogRef: MatDialogRef<CompanyProfileEditDialogComponent>,
    private confirmationDialog: MatDialog,
    private editContactDialog: MatDialog,
    private createContactDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { personId: string; companyProfileId: string },
    private snackbar: MatSnackBar
  ) {
    this.companyProfileForm = this.fb.group({
      businessRole: ['', Validators.required],
      brand: [''],
      website: [''],
      company: this.fb.group({
        name: ['', Validators.required],
        phone: [''],
        vatNumber: [''],
        agemCode: [''],
        address: [''],
        city: [''],
        province: [''],
        zipCode: [''],
        countryIso2: ['', [Validators.minLength(2), Validators.maxLength(2)]],
        numberEmployees: [''],
        website: [''],
        sales: [''],
      }),
    });
    this.newContactForm = this.fb.group({
      contactType: ['', Validators.required],
      contactValue: ['', Validators.required],
      isPreferred: [false],
    });
  }

  private setterIsLoading(value: boolean): () => void {
    return () => {
      this.isLoading = value;
    };
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  ngOnInit(): void {
    this.apiCmdService.getPersonCompanyProfile(this.data.personId, this.data.companyProfileId).subscribe({
      next: response => {
        this.companyProfileForm.patchValue(response.data);
        this.personContacts = response.data.personContacts;
        this.isLoading = false;
      },
      error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiCmdService.deletePersonCompanyProfile(this.data.personId, this.data.companyProfileId).subscribe({
          next: () => {
            this.dialogRef.close(true);
          },
          error: err => handlerSnackbarApiError(this.snackbar, err),
        });
      }
    });
  }

  onSubmit(): void {
    if (this.companyProfileForm.valid) {
      this.isSubmitting = true;
      const updateDto: DataUpdatePersonCompanyProfileApiRequestDto['data'] = this.companyProfileForm.value;
      Object.keys(updateDto).forEach(key => {
        // @ts-expect-error TS7053
        if (updateDto[key] === '') updateDto[key] = null;
      });
      if (updateDto.company)
        Object.keys(updateDto.company).forEach(key => {
          // @ts-expect-error TS7053
          if (updateDto.company[key] === '') updateDto.company[key] = null;
        });
      this.apiCmdService.updatePersonCompanyProfile(this.data.personId, this.data.companyProfileId, { data: updateDto }).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.dialogRef.close(true);
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
      });
    }
  }

  openEditContactDialog(contactId: string): void {
    const dialogRef = this.editContactDialog.open(ContactEditDialogComponent, {
      width: '400px',
      data: { personId: this.data.personId, contactId: contactId },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiCmdService.getPersonCompanyProfile(this.data.personId, this.data.companyProfileId).subscribe({
          next: response => {
            this.personContacts = response.data.personContacts;
          },
          error: err => handlerSnackbarApiError(this.snackbar, err),
        });
      }
    });
  }

  openCreateContactDialog(): void {
    const dialogRef = this.createContactDialog.open(ContactCreateDialogComponent, {
      width: '400px',
      data: { personId: this.data.personId, personCompanyProfileId: this.data.companyProfileId, contacts: this.personContacts },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiCmdService.getPersonCompanyProfile(this.data.personId, this.data.companyProfileId).subscribe({
          next: response => {
            this.personContacts = response.data.personContacts;
          },
          error: err => handlerSnackbarApiError(this.snackbar, err),
        });
      }
    });
  }
}
